import {
  Container,
  Row,
  Col
} from 'reactstrap';
import React, { useState, useEffect } from 'react';
import styles from './styles.module.scss';
import clsx from 'classnames';
import PropTypes from 'prop-types';
import Link from 'next/link';
import Logo from '/public/site-assets/svg/logo.svg';
import Caret from '/public/site-assets/svg/caret.svg';
import HeaderNavigation1 from '@/.sourceflow/header_navigation_1.json';
import HeaderNavigation2 from '@/.sourceflow/header_navigation_2.json';
import BaseObject from '@sourceflow-uk/sourceflow-sdk/base_object';
import { useLocale } from '@/hooks/useLocale';
import EditableText from "@/components/EditableText";
import global from '@/.sourceflow/global.json';
import image from '@/.sourceflow/image_metadata.json';
import EditableImage from "@/components/EditableImage";
import Button from "@/components/Button";
import sourceflowEnums from "@/sourceflow.enums";
import { useRouter } from 'next/router'; // Import useRouter hook
import JobSearchInput from "@/components/JobSearchInput";

export default function Header({ className }) {
  const router = useRouter();
  const [activeNavIndex, setActiveNavIndex] = useState(null);
  const [isSecondaryActive, setIsSecondaryActive] = useState(false);

  const ParentMainNavigation_1 = HeaderNavigation1.category_values.filter(post =>
    post.parent.identifier === null
  );
  const ParentMainNavigation_2 = HeaderNavigation2.category_values.filter(post =>
    post.parent.identifier === null
  );

  const ChildrenMainNavigation = HeaderNavigation1.category_values.filter(post =>
    post.parent.identifier != null
  );

  // const lastIndex = ParentMainNavigation_1.length - 1;
  const lastIndex = ParentMainNavigation_1.length - 0;

  const handleMouseEnter = (index) => {
    setActiveNavIndex(index);
  };

  const handleMouseLeave = () => {
    setActiveNavIndex(null);
  };


  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  //MENU STATES FOR MOBILE
  const [activeAccordion, setActiveAccordion] = useState(null);
  const toggleAccordion = (index) => {
    setActiveAccordion(activeAccordion === index ? null : index);
  };
  let SubNavigation;

  SubNavigation = HeaderNavigation1.category_values.sort((a, b) => a.order?.en - b.order?.en).map((item) => item).filter((item) => item.parent.id != null);



  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [isScrolled, setIsScrolled] = useState(false);


  useEffect(() => {
    // Update HTML overflow style based on isMenuOpen state
    const html = document.querySelector("html");
    if (html) {
      html.style.overflow = isMenuOpen ? "hidden" : "auto";
    }
  }, [isMenuOpen]);

  useEffect(() => {
    // Check scroll position on page load
    const currentScrollY = window.scrollY || document.documentElement.scrollTop;
    if (currentScrollY < 80) {
      setIsSecondaryActive(true);
    }
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset || document.documentElement.scrollTop;
      setIsScrolled(currentScrollPos > prevScrollPos && currentScrollPos > 100); // Change 100 to your desired threshold
      setPrevScrollPos(currentScrollPos);
      setIsSecondaryActive(false);

    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [prevScrollPos]);


  return (
    <>
      <header id="header" className={`${clsx(className, styles.root, { 'active': isScrolled })} ${router.pathname == "/" ? `secondary-header ${isSecondaryActive ? `secondary-active` : ""}` : ""}`}>
        <Container className='position-relative z-index-1 main-container'>
          <div className='wrapper d-flex gap-3 justify-content-between align-items-center'>
            <Link href={`/`} title={`${sourceflowEnums.seo.siteName}`} className='logo d-block' aria-label={`${sourceflowEnums.seo.siteName}`}>
              <Logo />
            </Link>
            {ParentMainNavigation_1.length > 0 && (
              <ul className="list-unstyled d-none d-xxl-flex flex-wrap m-0 main-navigation gap-3">
                {ParentMainNavigation_1.map((parentPost, index) => (
                  <li
                    key={index}
                    className={`navItem ${parentPost.url.en != "$" ? "with-children" : ""}`}
                    onMouseEnter={index !== lastIndex ? () => handleMouseEnter(index) : undefined}
                    onMouseLeave={index !== lastIndex ? handleMouseLeave : undefined}
                  >

                    {parentPost?.new_tab?.en ? (
                      <a href={`${parentPost.url.en}`} target="_blank" rel="noopener noreferrer">
                        {parentPost.title.en}<Caret />
                      </a>
                    ) : (
                      <a href={`${parentPost.url.en}`}>
                        {parentPost.title.en}<Caret />
                      </a>
                    )}
                  </li>
                ))}
              </ul>
            )}
            {ParentMainNavigation_2.length > 0 && (
              <ul className="list-unstyled d-flex flex-wrap m-0 secondary-navigation gap-4 gap-lg-2 align-items-center">
                {ParentMainNavigation_2.map((parentPost, index) => (
                  <li
                    key={index}
                    className="navItem"
                  >

                    {parentPost?.new_tab?.en ? (
                      <a href={`${parentPost.url.en}`} target="_blank" rel="noopener noreferrer">
                        {parentPost.title.en}
                      </a>
                    ) : (
                      <a href={`${parentPost.url.en}`}>
                        {parentPost.title.en}
                      </a>
                    )}
                  </li>
                ))}
                <li className='d-flex align-items-center'>
                  <button onClick={toggleMenu} className={`mobile-nav-toggle d-xxl-none ${isMenuOpen ? 'active' : ''}`}
                          title="Menu" aria-label="Menu"><span>Menu</span></button>
                </li>
              </ul>
            )}
          </div>
        </Container>
        <div className={clsx('px-5 dropdownMainWrapper', {active: activeNavIndex !== null})}>
          {ParentMainNavigation_1.length > 0 && (
            ParentMainNavigation_1.map((parentPost, parentIndex) => (
              <div className={`dropdownItem ${parentPost.url_slug} ${activeNavIndex === parentIndex ? "active" : ""}`}
                onMouseEnter={() => handleMouseEnter(parentIndex)}
                onMouseLeave={handleMouseLeave}
                key={parentIndex}>
                <div className='inner-dropdown'>
                  <Container>
                    <div className='parent-title'>{parentPost.title.en}</div>
                    <div className="dropdownMenu d-flex justify-content-between gap-5">
                      <div className='dropdownNavigations column'>
                        <div>
                          {ChildrenMainNavigation.filter((childPost, childIndex) =>
                            childPost.parent.id === parentPost.id
                          ).map((childPost, childpostIndex) => (
                            <div className="child-nav" key={childPost.id}>
                              <div>
                                <a className="sub-item-parent" href={childPost.url.en}>{childPost.title.en}</a>
                                {childPost.subtitle?.en && (<div className='subtitle mb-1'>{childPost.subtitle?.en}</div>)}
                              </div>
                              {ChildrenMainNavigation.filter((sub_childPost, sub_childIndex) =>
                                sub_childPost.parent.id === childPost.id
                              ).map((sub_childPost) => (
                                <div key={sub_childPost.id}>
                                  <a className="sub-item" href={sub_childPost.url.en}>{sub_childPost.title.en}</a>
                                </div>
                              ))}
                            </div>
                          ))}
                        </div>
                      </div>
                      <div className='column right'>
                        <div className='dropdownMenuJobSearchTitle'>Find your ideal classroom job</div>
                        <JobSearchInput
                          locationInput={false}
                          radiusInput={false}
                        />
                      </div>
                    </div>
                  </Container>
                </div>
              </div>
            ))
          )}
        </div>
        <div className={`mobile-navigation d-xxl-none ${isMenuOpen ? 'active' : ''}`}>
          <Container>
            {ParentMainNavigation_1.length > 0 && (
              <ul className="list-unstyled d-flex flex-wrap m-0 main-navigation">
                {ParentMainNavigation_1.map((parentPost, index) => (
                  <li
                    key={index}
                    className="navItem w-100"
                  >
                    <>
                      <div className='d-flex justify-content-between'>
                        {parentPost?.new_tab?.en ?
                          <a href={`${parentPost.url.en}`} target="_blank" rel="noopener noreferrer">
                            {parentPost.title.en}
                          </a> :
                          <a href={`${parentPost.url.en}`}>
                            {parentPost.title.en}
                          </a>}
                        {SubNavigation?.some(subpost => subpost.parent.identifier === parentPost.title?.en) && (
                          <button
                            className={` ${activeAccordion === index ? 'active' : ''} childrens-caret`}
                            aria-label="Dropdown"
                            onClick={() => toggleAccordion(index)}
                          ></button>
                        )}
                      </div>
                      {SubNavigation?.some(subpost => subpost.parent.identifier === parentPost.title?.en) && (
                        <div className={`mt-2 ${activeAccordion === index ? 'd-block' : 'd-none'}`}>
                          {SubNavigation.map((sub_subpost, sub_subIndex) => (
                            sub_subpost.parent.identifier === parentPost.title?.en && (
                              <div className="childrens-navitem ps-3" key={sub_subIndex}>
                                <a href={`${sub_subpost.url?.en}`} {...(sub_subpost.new_tab?.en === true ? { "target": "_blank", "rel": "noopener noreferrer" } : { "target": "_self" })}>
                                  {sub_subpost.title?.en}
                                </a>
                              </div>
                            )
                          ))}
                        </div>
                      )}
                    </>
                  </li>
                ))}
                <li className='navItem w-100'>
                  <a href="/jobs" target="_self">Search Jobs</a>
                </li>
                <li className='navItem w-100'>
                  <a href="https://tranza.my.site.com/NT/s/login/" target="_blank">Timesheet</a>
                </li>
              </ul>
            )}
          </Container>
        </div>
      </header >
    </>
  );
}

Header.defaultProps = {
  pathPrefix: "",
  className: "",
};

Header.propTypes = {
  pathPrefix: PropTypes.string,
  className: PropTypes.string,
};