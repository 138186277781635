import {
    Container,
    Row,
    Col
} from 'reactstrap';
import styles from './styles.module.scss';
import EditableText from "@/components/EditableText";
import clsx from "classnames";
import PropTypes from "prop-types";
// import JobSearch from "@sourceflow-uk/job-search";
import { JobSearchStandaloneWidget } from "@sourceflow-uk/job-search";
import '@sourceflow-uk/job-search/dist/bundle.css';
// import JobCard from "@/components/Shared/JobCard";
// import sourceflowEnums from "@/sourceflow.enums";

// import job_remote_working from '@/.sourceflow/jobs-remote_working.json';
// import job_employment_type from '@/.sourceflow/jobs-employment_type.json';
// import job_divisions from '@/.sourceflow/jobs-Divisions.json';
// import job_offices from '@/.sourceflow/jobs-Offices.json';
// import job_careers from '@/.sourceflow/jobs-Careers.json';

export default function JobSearchInput({
    className,
    global,
    pathPrefix,
    locationInput = true,
    radiusInput = true
}) {
    return (
        <section className={`${clsx(className, styles.root)}`}>
            {/* <JobSearch
                translations={{
                    "button.text": "Search Jobs",
                    "input.placeHolder": "Enter job title, keywords, locations...",
                }}
                options={{
                    searchResults: false,
                    searchFilters: false,
                    submitDestination: "/jobs/",
                    searchInput: {
                        locationInput: false,
                        searchTotalCount: false
                    },
                }}
            // translations={{
            //   "input.placeHolder": "Search for 200 Jobs",
            // }}
            /> */}
            <JobSearchStandaloneWidget
                searchInput={{
                    queryInput: true,
                    locationInput: locationInput,
                    radiusInput: radiusInput,
                    radiusOptions: {
                        units: "miles",
                        values: [5, 10, 20, 30, 40, 50],
                        // default: 5
                    },
                }}
                submitDestination='/jobs'
                translations={{
                    "input.placeHolder": "Search by keyword",
                    "input.locationPlaceholder": "Search by location",
                    "button.text": "Search Jobs",
                    "input.radius.milesUnit": "miles",
                    "input.radius.kilometresUnit": "km",
                }}
            />

        </section >
    )
}
JobSearchInput.defaultProps = {
    pathPrefix: "",
    className: "",

};

JobSearchInput.propTypes = {
    pathPrefix: PropTypes.string,
    className: PropTypes.string,
};