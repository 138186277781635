import SiteHeader from "./SiteHeader";
import SiteFooter from "./SiteFooter";

/**
 *
 * @param children
 * @returns {JSX.Element}
 */
export default function Layout({ children }) {
  return (
    <>
      <SiteHeader />
      <main>{children}</main>
      <SiteFooter />
    </>
  );
}
