import React from "react";
import SourceFlowText from "@sourceflow-uk/sourceflowtext";

export default function EditableText({
    global,
    className = "",
    path,
    content,
    html,
    tag }) {
    return (
        <SourceFlowText
            tag={tag ? tag : `span`}
            global={global}
            path={path}
            type={html ? "html" : "text"}
            className={`${className}`}
        >
            {content}
        </SourceFlowText>
    );
}