import React from "react";
import clsx from "classnames";
import styles from './styles.module.scss';
import Arrow from '/public/site-assets/svg/button-arrow.svg';

export default function Button({
    text,
    url,
    button_new_tab,
    className,
    onClick,
    showArrow = true,
    buttonDom = false
}) {
    return (
        <>
            {button_new_tab ?
                <a href={url} className={`${clsx(className, styles.root)} ${showArrow ? "show-arrow" : ""}`} target="_blank" rel="noopener noreferrer">
                    <span>{text}{showArrow && (<Arrow />)}</span>
                </a> :
                buttonDom ?
                    <button className={`${clsx(className, styles.root)} ${showArrow ? "show-arrow" : ""}`} onClick={onClick} role="button" aria-label={text}>{text}</button >
                    :
                    <a href={url} className={`${clsx(className, styles.root)} ${showArrow ? "show-arrow" : ""}`} onClick={onClick} target="_self">
                        <span> {text}{showArrow && (<Arrow />)}</span>
                    </a>
            }
        </>
    );
}