import {
  Container,
  Row,
  Col,
  Card
} from 'reactstrap';
import styles from './styles.module.scss';
import clsx from "classnames";
import PropTypes from "prop-types";
import Link from "next/link"
import SocialLinks from '@/.sourceflow/social_links.json';
import Facebook from '@/public/site-assets/svg/facebook.svg';
import Twitter from '@/public/site-assets/svg/twitter.svg';
import Instagram from '@/public/site-assets/svg/instagram.svg';
// import Youtube from '@/public/site-assets/svg/youtube.svg';
import LinkedIn from '@/public/site-assets/svg/linkedin.svg';
import Tiktok from '@/public/site-assets/svg/tiktok.svg';
import EditableText from "@/components/EditableText";

export default function SocialShare({
  className,
  global,
  pathPrefix,
  share_heading

}) {
  return (
    SocialLinks?.category_values && (
      <div className={clsx(className, styles.root)}>
        {share_heading && (
          <div>
            <EditableText
              content={share_heading}
              path={`${pathPrefix}.share_heading`}
              global={global}
              tag={``}
              className={``}
              html={false}
            />
          </div>
        )}
        <ul className='list-unstyled d-flex flex-wrap justify-content-between gap-2'>
          {SocialLinks?.category_values.map((post, index) => (
            <li key={index} className=''>
              <Link href={`${post.link.en}`} target="_blank" rel="nofollow noopener noreferrer" aria-labelledby={`${post.title.en}`} title={`${post.title.en}`}>
                {post.template.en == "Facebook"
                  ? <Facebook /> :
                  post.template.en == "Twitter" ?
                    <Twitter /> :
                    post.template.en == "Tiktok" ?
                      <Tiktok /> :
                      post.template.en == "Instagram" ?
                        <Instagram /> :
                        post.template.en == "LinkedIn" ?
                          <LinkedIn /> :
                          ""
                }
              </Link>
            </li>
          ))}
        </ul>
      </div>
    )
  )
}


SocialShare.defaultProps = {
  pathPrefix: "",
  className: "py-4",
};

SocialShare.propTypes = {
  pathPrefix: PropTypes.string,
  className: PropTypes.string,
};